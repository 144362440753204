import { styled, typography } from '@pulse-web-ui/theme';

export const Hint = styled.div<{ $isError: boolean }>`
  ${typography.regular14};
  line-height: 20px;
  display: flex;
  gap: 4px;
  color: ${({ theme, $isError }) =>
    $isError ? theme.colors.text.error : theme.colors.text.secondary};
  margin-bottom: 4px;
`;

export const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: auto;
  width: 20px;
  height: 20px;
`;
