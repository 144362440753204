import { useCallback } from 'react';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';

import { HelperText } from '@pulse-web-ui/helper-text';
import { AutocompleteSelect } from '@pulse-web-ui/autocomplete-select';
import type { AutocompleteSelectProps } from '@pulse-web-ui/autocomplete-select/lib/autocomplete-select.types';

type AutocompleteSelectControllerProps<T extends FieldValues> =
  UseControllerProps<T> &
    AutocompleteSelectProps & {
      hint?: string;
      isShowHintWhenNotDirty?: boolean;
    };

export const AutocompleteSelectController = <T extends FieldValues>({
  placeholder,
  fieldOnChange,
  onBlur,
  ariaLabel,
  arrowWidth,
  hint,
  isShowHintWhenNotDirty,
  options,
  label,
  onChange,
  disabled,
  isLoading,
  testId = 'autocompleteSelectController',
  ...controllerProps
}: AutocompleteSelectControllerProps<T>) => {
  const { field, fieldState } = useController(controllerProps);
  const helperTextMessageHint =
    hint && (isShowHintWhenNotDirty ? !fieldState.isDirty : true)
      ? hint
      : undefined;

  const handleChange = useCallback(
    (value?: string) => {
      field.onChange(value);
      onChange?.(value);
    },
    [field, onChange]
  );

  return (
    <HelperText
      status={!!fieldState.error ? 'error' : 'default'}
      message={fieldState.error?.message || helperTextMessageHint}
      testId={`${testId}.error`}
    >
      <AutocompleteSelect
        onChange={handleChange}
        value={field.value}
        options={options}
        label={label}
        status={fieldState.error && 'error'}
        isLoading={isLoading || options?.length === 0}
        testId={testId}
        disabled={disabled}
        placeholder={placeholder}
        fieldOnChange={fieldOnChange}
        onBlur={onBlur}
        ariaLabel={ariaLabel}
        arrowWidth={arrowWidth}
      />
    </HelperText>
  );
};
