import * as yup from 'yup';
import { emailRegEx, phoneRegEx } from '@shared/regexps';
import i18n from '@src/app/i18n/config';
import {
  firstNameSchema,
  lastNameSchema,
  middleNameSchema,
} from './fio-schema';

export const registrationSchema = yup
  .object()
  .shape({
    lastName: lastNameSchema,
    firstName: firstNameSchema,
    middleName: middleNameSchema,
    noMiddleName: yup.boolean(),
    birthDate: yup.string().required(i18n.t('COMMON:errors.required')),
    phone: yup
      .string()
      .required(i18n.t('COMMON:errors.required'))
      .matches(phoneRegEx, i18n.t('COMMON:errors.incorrectPhoneNumber')),
    email: yup
      .string()
      .required(i18n.t('COMMON:errors.required'))
      .matches(emailRegEx, i18n.t('COMMON:errors.incorrectEmail')),
  })
  .required();
