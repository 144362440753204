import { styled, typography } from '@pulse-web-ui/theme';

export const ChangePhoneButton = styled.div`
  display: inline-flex;
  ${typography.regular14};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.interactive};
  cursor: pointer;
  margin-top: 8px;
`;
