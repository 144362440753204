import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';

import { Datepicker, type DatepickerProps } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { addTestAttribute } from '@shared/index';

import { DatepickerContainerStyles } from './detepicker-controller.styles';

type Props<T extends FieldValues> = UseControllerProps<T> &
  Omit<DatepickerProps, 'onChange'> & {
    label: string;
    id?: string;
    minDate?: Date;
    maxDate?: Date;
    onChange?: (val: string) => void;
    testId?: string;
  };

export const DatepickerController = <T extends FieldValues>({
  name,
  control,
  label,
  id,
  minDate,
  maxDate,
  onChange,
  showMonthDropdown,
  showYearDropdown,
  showInput,
  testId = 'form',
}: Props<T>) => {
  const { field, fieldState } = useController({ name, control });

  return (
    <>
      <HelperText
        status={!!fieldState.error ? 'error' : 'default'}
        message={fieldState.error?.message}
      >
        <Datepicker
          label={label}
          id={id}
          name={name}
          selected={field.value ? new Date(field.value) : undefined}
          onChange={(data) => {
            field.onChange(data);
            onChange && onChange(field.value);
          }}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          error={!!fieldState.error}
          minDate={minDate}
          maxDate={maxDate}
          showInput={showInput}
          disabledKeyboardNavigation
          {...addTestAttribute(`${testId}.datepicker`)}
        />
      </HelperText>
      <DatepickerContainerStyles />
    </>
  );
};
