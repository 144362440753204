import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useErrorBoundary } from 'react-error-boundary';
import { observer } from 'mobx-react-lite';

import {
  BoundaryErrorType,
  CheckboxController,
  SubmitButton,
  useStores,
} from '@shared/index';
import { useRefreshCode } from '@entities/index';

import {
  type StandardsAgreementProps,
  standardsAgreementSchema,
} from '../model';

interface Props {
  onSuccessSubmit: () => void;
}

export const StandardAgreement = observer(({ onSuccessSubmit }: Props) => {
  const { t } = useTranslation('STANDARDS');
  const {
    MainStore: {
      agentContractStore: { profile },
      agentUserStore: { setConfirmToken },
    },
  } = useStores();
  const { showBoundary } = useErrorBoundary();

  const { refetch, error } = useRefreshCode({
    phone: profile?.phone || '',
    deps: [profile?.phone],
  });

  const methods = useForm<StandardsAgreementProps>({
    resolver: yupResolver(standardsAgreementSchema),
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      standardsAgreement: false,
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = methods;

  const handleFormSubmit = handleSubmit(() => {
    refetch().then(({ data }) => {
      if (data?.data?.token) {
        setConfirmToken(data.data.token);
        onSuccessSubmit();
      }
    });
  });

  useEffect(() => {
    if (error) {
      showBoundary(BoundaryErrorType.SomethingWentWrong);
    }
  }, [error]);

  return (
    <>
      <CheckboxController
        control={control}
        name="standardsAgreement"
        id="standardsAgreement"
        label={t('labels.standardAgreement')}
      />
      <SubmitButton
        label={t('buttons.confirm')}
        onClick={handleFormSubmit}
        disabled={!!Object.keys(errors)?.length}
      />
    </>
  );
});
