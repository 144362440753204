import * as yup from 'yup';
import i18n from '@src/app/i18n/config';
import { onlyLetters, onlyCyrillic, maxLength } from '@shared/regexps';

const defaultFioSchema = yup
  .string()
  .required(i18n.t('COMMON:errors.required'))
  .min(1, i18n.t('COMMON:errors.minLength'))
  .max(50, i18n.t('COMMON:errors.maxLength'))
  .matches(onlyCyrillic, i18n.t('COMMON:errors.useCyrillic'));

export const lastNameSchema = defaultFioSchema.matches(
  onlyLetters,
  i18n.t('COMMON:errors.lastnameStartWithLetter')
);

export const firstNameSchema = defaultFioSchema.matches(
  onlyLetters,
  i18n.t('COMMON:errors.nameStartWithLetter')
);

export const middleNameSchema = yup.string().when('noMiddleName', {
  is: false,
  then: () =>
    yup
      .string()
      .required(i18n.t('COMMON:errors.required'))
      .test(
        'onlyLetters',
        i18n.t('COMMON:errors.middleNameStartWithLetter'),
        (value) => (value?.length ? onlyLetters.test(value!) : true)
      )
      .test('onlyCyrillic', i18n.t('COMMON:errors.useCyrillic'), (value) =>
        value?.length ? onlyCyrillic.test(value!) : true
      )
      .test('maxLength', i18n.t('COMMON:errors.maxLength'), (value) =>
        value?.length ? maxLength.test(value!) : true
      ),
});
