import {
  atLeastOneSpecialCharacter,
  atLeastThreeLowerCaseCharacters,
  atLeastOneDigit,
  atLeastOneUpperCaseCharacter,
} from '@shared/regexps';

export const validatePasswordCharacters = (password: string) => {
  const hasAtLeastOneLowerCaseCharacter =
    atLeastThreeLowerCaseCharacters.test(password);
  const hasAtLeastOneDigit = atLeastOneDigit.test(password);
  const hasAtLeastOneUpperCaseCharacter =
    atLeastOneUpperCaseCharacter.test(password);

  return (
    hasAtLeastOneLowerCaseCharacter &&
    hasAtLeastOneDigit &&
    hasAtLeastOneUpperCaseCharacter
  );
};

export const validatePassword = (password: string) => {
  const hasAtLeastOneSpecialCharacter =
    atLeastOneSpecialCharacter.test(password);

  return hasAtLeastOneSpecialCharacter && validatePasswordCharacters(password);
};
