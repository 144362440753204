import React, { type KeyboardEventHandler, memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CheckboxController,
  DadataFioController,
  DatepickerController,
  FormControlContainer,
  InputController,
  KeyCode,
  getDateForAge,
} from '@shared/index';
import { FilterParts } from '@entities/index';

import { RGSAgentRegistrationButton } from './rgs-agent-registration-button';
import type { RegistrationFormProps } from '../model/types/registration-form.types';

const maxBirthDate = getDateForAge(18);
maxBirthDate.setDate(maxBirthDate.getDate() - 1);
const minBirthDate = getDateForAge(100);

export const Registration = memo(() => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useFormContext<RegistrationFormProps>();

  const middleNameNotRequired = watch('noMiddleName');

  const handleClearErrors = useCallback(() => {
    if (errors) {
      clearErrors();
    }
  }, [errors]);

  const handleKeyDown = useCallback(
    (inputIndex: number): KeyboardEventHandler<HTMLInputElement> =>
      (e) => {
        e.keyCode === KeyCode.ENTER &&
          document.getElementsByTagName('input')[inputIndex].focus();
      },
    []
  );

  const handleFioChange = useCallback(
    (name: keyof RegistrationFormProps) => (value: string) => {
      clearErrors();
      setValue(name, value);
    },
    []
  );

  const handleCheckboxChange = useCallback(() => {
    clearErrors('middleName');
    setValue('middleName', '');
  }, []);

  return (
    <>
      <RGSAgentRegistrationButton />
      <FormControlContainer>
        <DadataFioController<RegistrationFormProps>
          control={control}
          name="lastName"
          filterParts={[FilterParts.SURNAME]}
          id="dadata-lastName"
          label={t('REGISTRATION:labels.lastName') || ''}
          onChange={handleFioChange('lastName')}
          onKeyDown={handleKeyDown(1)}
          testId="registration.lastName"
        />
      </FormControlContainer>
      <FormControlContainer>
        <DadataFioController<RegistrationFormProps>
          control={control}
          name="firstName"
          filterParts={[FilterParts.NAME]}
          label={t('REGISTRATION:labels.firstName') || ''}
          id="dadata-firstName"
          onChange={handleFioChange('firstName')}
          onKeyDown={handleKeyDown(1)}
          testId="registration.firstName"
        />
      </FormControlContainer>
      <FormControlContainer>
        <DadataFioController<RegistrationFormProps>
          control={control}
          name="middleName"
          id="dadata-middleName"
          label={t('REGISTRATION:labels.middleName') || ''}
          filterParts={[FilterParts.PATRONYMIC]}
          onChange={handleFioChange('middleName')}
          onKeyDown={handleKeyDown(4)}
          disabled={middleNameNotRequired}
          testId="registration.middleName"
        />
      </FormControlContainer>
      <FormControlContainer>
        <CheckboxController<RegistrationFormProps>
          control={control}
          name="noMiddleName"
          label={t('REGISTRATION:labels.noMiddleName') || ''}
          onChange={handleCheckboxChange}
          testId="registration.noMiddleName"
        />
      </FormControlContainer>
      <FormControlContainer>
        <DatepickerController<RegistrationFormProps>
          control={control}
          name="birthDate"
          label={t('REGISTRATION:labels.birthDate') || ''}
          id="birthDate"
          onChange={handleClearErrors}
          minDate={minBirthDate}
          maxDate={maxBirthDate}
          showMonthDropdown
          showYearDropdown
          showInput
          testId="registration.birthDate"
        />
      </FormControlContainer>
      <FormControlContainer>
        <InputController<RegistrationFormProps>
          control={control}
          name="phone"
          label={t('COMMON:labels.phone') || ''}
          id="phone"
          onChange={handleClearErrors}
          type="tel"
          testId="registration.phone"
        />
      </FormControlContainer>
      <FormControlContainer>
        <InputController<RegistrationFormProps>
          control={control}
          name="email"
          label={t('REGISTRATION:labels.email') || ''}
          id="email"
          onChange={handleClearErrors}
          type="text"
          testId="registration.email"
        />
      </FormControlContainer>
    </>
  );
});
