import { StyledContent, StyledText } from './text-with-tooltip.styles';
import { Tooltip, type TooltipProps } from '@pulse-web-ui/tooltip';

interface TextWithTooltipProps extends TooltipProps {
  text: string;
}

export const TextWithTooltip = ({
  text,
  ...tooltipProps
}: TextWithTooltipProps) => {
  return (
    <StyledContent>
      <StyledText>{text}</StyledText>
      <Tooltip {...tooltipProps} />
    </StyledContent>
  );
};
