import { createContext } from 'react';
import type { LoginRequestParams } from '@shared/types';
import type { AxiosError } from 'axios';

interface UserContextType {
  handleLogin: (params: LoginRequestParams) => void;
  handleLogout: () => void;
  isAuthenticated: boolean;
  loginError: AxiosError<{ code?: string }> | undefined | null;
  username: string | undefined | null;
  getUserName: () => string | null;
}

export const UserContext = createContext<UserContextType>({
  handleLogin: () => {},
  handleLogout: () => {},
  isAuthenticated: false,
  loginError: undefined,
  username: undefined,
  getUserName: () => null,
});
