import { makeAutoObservable, runInAction } from 'mobx';

import type { Main } from '@app/store';
import type { AgentUser } from '../types';
import type { AgentBill, AvailableSign } from '@entities/agent-contract';

//TODO: Удалить после объединения с AgentContractStore
export class AgentUserStore {
  private mainStore: Main;
  agent?: AgentUser;
  confirmToken?: string;
  agentBill?: AgentBill;
  availableSign?: AvailableSign;
  authSuccessfulText?: string;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setAgent = (agent: AgentUser) => {
    this.agent = agent;
  };

  setConfirmToken = (token: string) => {
    this.confirmToken = token;
  };

  setAgentBill = (bill: AgentBill) => {
    this.agentBill = bill;
  };

  setAvailableSign = (data: AvailableSign) => {
    this.availableSign = data;
  };

  setAuthSuccessfulText = (value: string | undefined) => {
    this.authSuccessfulText = value;
  };

  reset() {
    runInAction(() => {
      this.agent = undefined;
      this.confirmToken = undefined;
      this.agentBill = undefined;
      this.availableSign = undefined;
      this.authSuccessfulText = undefined;
    });
  }
}
