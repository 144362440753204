import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { Method, Url, RawAxiosHeaders } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { InstanceUrls, instance } from '../utils/requests';
const customFetch = async <TRequest, TResponse>({
  method,
  url,
  request,
  config,
  instanceUrl,
}: {
  method: Method;
  url: Url;
  request?: TResponse;
  config: AxiosRequestConfig<TRequest>;
  instanceUrl?: InstanceUrls;
}) => {
  const currentInstance = instance[instanceUrl || InstanceUrls.BASEURL];

  if (method === 'get') {
    return currentInstance.get<TRequest, AxiosResponse<TResponse>>(url, config);
  }

  return currentInstance[method]<TRequest, AxiosResponse<TResponse>>(
    url,
    request,
    config
  );
};

type UseQueryFunctionType<TRequest> = {
  method: Method;
  url: Url;
  request?: TRequest | undefined;
  requestConfig?: AxiosRequestConfig<TRequest>;
};

export const useQueryFunction = <TRequest, TResponse>(
  params: UseQueryFunctionType<TRequest>,
  instanceUrl?: InstanceUrls
) => {
  const { method, url, request, requestConfig } = params;
  let headers: RawAxiosHeaders = {
    'Content-type': 'application/json',
  };

  if (instanceUrl === InstanceUrls.DADATA_URL) {
    headers = {
      ...headers,
      'X-Process-ID': uuidv4(),
    };
  }
  const config = { headers, ...requestConfig };

  const queryFn = async (data: any) => {
    return customFetch<TRequest, TResponse>({
      method,
      url,
      request: request || data,
      config: requestConfig ? requestConfig : config,
      instanceUrl,
    });
  };

  return queryFn;
};
