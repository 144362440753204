import React, { memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControlContainer, InputController } from '@shared/index';
import type { AgentRegistrationFormProps } from '../model';

export const AgentRegistration = memo(() => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext<AgentRegistrationFormProps>();

  const handleClearErrors = useCallback(() => {
    if (errors) {
      clearErrors();
    }
  }, [errors]);

  return (
    <FormControlContainer>
      <InputController<AgentRegistrationFormProps>
        control={control}
        name="phone"
        label={t('COMMON:labels.phone') || ''}
        id="phone"
        onChange={handleClearErrors}
        type="tel"
        testId="registration.phone"
      />
    </FormControlContainer>
  );
});
