import React, { useCallback, useEffect, useState } from 'react';
import type { FocusEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { Option } from '@pulse-web-ui/autocomplete-select';

import {
  AutocompleteSelectController,
  FormControlContainer,
  InputController,
  useStores,
} from '@shared/index';

import {
  getBankNameByBic,
  getBicOptions,
  getCorAccountByBankName,
} from '../../dictionaries';
import type { AgentBankDetailsFormParams } from '../model';
import { observer } from 'mobx-react-lite';
import { AgentBankDetailsWrapper } from './agent-bank-details.styles';
import {
  BIC_PLACEHOLDER,
  NAME_BANK_MAX_LENGTH,
  ACCOUNT_MASK,
  ACCOUNT_PLACEHOLDER,
} from '../model/constants';

interface AgentBankDetailsProps {
  setIsLoading: (isLoading: boolean) => void;
}

export const AgentBankDetails = observer(
  ({ setIsLoading }: AgentBankDetailsProps) => {
    const { t } = useTranslation();
    const {
      MainStore: {
        dictionariesStore: { bicDictionary, isBicDictionaryLoaded },
        agentContractStore: { profile },
      },
    } = useStores();
    const [options, setOptions] = useState<Option[]>([]);
    const [isCorAccountDisabled, setIsCorAccountDisabled] =
      useState<boolean>(true);
    const [isBankAccountDisabled, setIsBankAccountDisabled] =
      useState<boolean>(true);

    const {
      control,
      formState: { errors },
      clearErrors,
      getValues,
      setError,
      setValue,
    } = useFormContext<AgentBankDetailsFormParams>();

    const onInputControllerChange = () => {
      if (errors) {
        clearErrors();
      }
    };

    const handleBicChange = useCallback(
      (value: string | undefined) => {
        if (!!value) {
          if (errors) {
            clearErrors();
          }

          const bankName =
            getBankNameByBic(
              bicDictionary,
              value?.replace(/[^0-9]/g, '') || ''
            ) || '';
          setValue('nameBank', bankName || '');
          setValue('bankAccount', '');
          setIsBankAccountDisabled(false);
          getCorAccount(bankName);
        } else {
          setValue('bic', '');
        }
      },
      [bicDictionary]
    );

    const getCorAccount = useCallback(
      (bankName: string) => {
        const corAcount = getCorAccountByBankName(
          bicDictionary,
          bankName || ''
        );
        if (corAcount) {
          setValue('corAccount', corAcount);
          setIsCorAccountDisabled(true);
        } else {
          setValue('corAccount', '');
          setIsCorAccountDisabled(false);
        }

        setIsBankAccountDisabled(false);
      },
      [bicDictionary]
    );

    const handleBicFieldChange = useCallback(
      (bicValue: string | undefined) => {
        if (errors) {
          clearErrors();
        }
        const digitalValue = bicValue?.replace(/[^0-9]/g, '');

        if (!!digitalValue) {
          const foundedName = getBankNameByBic(bicDictionary, digitalValue);

          if (foundedName) {
            setValue('bic', digitalValue);
            setValue('nameBank', foundedName);
            setValue('bankAccount', '');
            getCorAccount(foundedName);
          }
        } else {
          setValue('bic', '');
          setValue('nameBank', '');
          setValue('corAccount', '');
          setValue('bankAccount', '');
        }
      },
      [bicDictionary]
    );

    const handleBicBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        const bicValue = event.target.value;

        if (
          !!bicValue &&
          !options?.some((option) => option.label.includes(bicValue))
        ) {
          setError('bic', {
            type: 'string',
            message: t('AGENT_BANK_DETAILS:errors.bicNotFound'),
          });
          setValue('nameBank', '');
          setValue('corAccount', '');
          setValue('bankAccount', '');
          setIsCorAccountDisabled(true);
          setIsBankAccountDisabled(true);
        }
      },
      [options]
    );

    useEffect(() => {
      if (isBicDictionaryLoaded && options.length === 0) {
        setOptions(getBicOptions(bicDictionary));
      }
    }, [isBicDictionaryLoaded, options.length]);

    useEffect(() => {
      if (!!options.length) {
        setValue('bic', profile?.bankDetails?.bic || '');
        setIsBankAccountDisabled(false);

        const corAcount = getCorAccountByBankName(
          bicDictionary,
          getValues('nameBank') || ''
        );

        if (corAcount) {
          setIsCorAccountDisabled(true);
        } else {
          setIsCorAccountDisabled(false);
        }

        setIsLoading(false);
      }
    }, [options.length]);

    return (
      <AgentBankDetailsWrapper>
        <FormControlContainer>
          <AutocompleteSelectController
            control={control}
            name="bic"
            label={t('AGENT_BANK_DETAILS:labels.bic') || ''}
            placeholder={BIC_PLACEHOLDER}
            onChange={handleBicChange}
            onBlur={handleBicBlur}
            fieldOnChange={handleBicFieldChange}
            arrowWidth={49}
            options={options}
            testId="agentBankDetails.bic"
          />
        </FormControlContainer>
        <FormControlContainer>
          <InputController
            control={control}
            name="nameBank"
            label={t('AGENT_BANK_DETAILS:labels.name') || ''}
            id="nameBank"
            maxLength={NAME_BANK_MAX_LENGTH}
            onChange={onInputControllerChange}
            disabled
            testId="agentBankDetails.bankName"
          />
        </FormControlContainer>
        <FormControlContainer>
          <InputController
            control={control}
            name="corAccount"
            label={t('AGENT_BANK_DETAILS:labels.corAccount') || ''}
            id="corAccount"
            mask={ACCOUNT_MASK}
            placeholder={ACCOUNT_PLACEHOLDER}
            disabled={isCorAccountDisabled}
            testId="agentBankDetails.corAccount"
            onChange={onInputControllerChange}
          />
        </FormControlContainer>
        <FormControlContainer>
          <InputController
            control={control}
            name="bankAccount"
            hint={t('AGENT_BANK_DETAILS:hints.bankAccount')}
            label={t('AGENT_BANK_DETAILS:labels.bankAccount') || ''}
            id="bankAccount"
            mask={ACCOUNT_MASK}
            placeholder={ACCOUNT_PLACEHOLDER}
            disabled={isBankAccountDisabled}
            onChange={onInputControllerChange}
            testId="agentBankDetails.bankAccount"
          />
        </FormControlContainer>
      </AgentBankDetailsWrapper>
    );
  }
);
