import { FioSuggestions } from '@pulse-web-ui/dadata';

import type { DadataFioSuggestionsProps } from './dadata-fio-suggestions.types';
import { RequestMethods, useQueryFunction, InstanceUrls } from '@src/shared';

export const DadataFioSuggestions = (props: DadataFioSuggestionsProps) => {
  const queryFn = useQueryFunction(
    {
      method: RequestMethods.POST,
      url: 'api/v3/dadata/suggest/fio',
    },
    InstanceUrls.DADATA_URL
  );

  return (
    <FioSuggestions
      isCapitalizeFirst
      delay={250}
      minChars={1}
      customRequest={queryFn}
      {...props}
    />
  );
};
