import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useErrorBoundary } from 'react-error-boundary';

import type { CreatePasswordFormProps } from '@features/create-password';
import {
  BoundaryErrorType,
  OTP,
  AnalyticEvent,
  getMismatches,
  phoneValueCasting,
  useStores,
  addTestAttribute,
  SubmitButton,
  getConsents,
  AGENT_NOT_FOUND,
  FlkCode,
} from '@shared/index';
import type { AgreementsFormProps } from '@features/agreements';
import { sendAnalyticEvent } from '@app/web-analytic';
import { useCreateImportedAgent } from '@entities/index';
import type {
  CreateImportedAgentRequestParams,
  AgentRegistrationFormProps,
} from '@entities/index';

export const SubmitAgentRegistration = observer(() => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();

  const {
    MainStore: {
      agentUserStore: { setAgent, setConfirmToken },
    },
  } = useStores();

  const [requestParams, setRequestParams] =
    useState<CreateImportedAgentRequestParams>({
      agent: {
        phone: '',
        password: '',
      },
      consents: [],
    });

  const { res, refetch, error, isFetching } = useCreateImportedAgent(
    {
      ...requestParams,
    },
    [requestParams]
  );

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = useFormContext<
    AgentRegistrationFormProps & CreatePasswordFormProps & AgreementsFormProps
  >();

  useEffect(() => {
    if (!!requestParams.agent.phone) {
      refetch();
    }
  }, [requestParams]);

  useEffect(() => {
    if (!isFetching && res) {
      setAgent(res.agent);
      setConfirmToken(res.token);
      sendAnalyticEvent(AnalyticEvent.AGENT_REG_TO_STEP_OTP);
      navigate(OTP);
    }
  }, [isFetching, res]);

  useEffect(() => {
    if (error) {
      const mismatches = getMismatches(error);

      if (!!mismatches) {
        let hasKnownErrors = false;
        mismatches.forEach(({ code }) => {
          if (code === FlkCode.AGT_4701_0002) {
            navigate(AGENT_NOT_FOUND);
            hasKnownErrors = true;
          } else if (code === FlkCode.RMM_6010_0003) {
            // Неккоректный телефон
            setError('phone', {
              message: t('COMMON:errors.incorrectPhoneNumber'),
            });
            hasKnownErrors = true;
          } else if (code === FlkCode.RMM_6010_0004) {
            // Неккоректный пароль
            setError('password', {
              message: t('PASSWORD:errors.invalidCharacters'),
            });
            hasKnownErrors = true;
          }
        });

        if (!hasKnownErrors) {
          showBoundary(BoundaryErrorType.SomethingWentWrong);
        }
      } else {
        showBoundary(BoundaryErrorType.SomethingWentWrong);
      }
    }
  }, [error]);

  const handleFormSubmit = handleSubmit((values) => {
    const { phone, personalDataAgreement, specialOffersAgreement, password } =
      values;

    setRequestParams({
      agent: {
        phone: phoneValueCasting(phone),
        password,
      },
      consents: getConsents({
        personalDataAgreement,
        specialOffersAgreement,
      }),
    });
  });

  return (
    <SubmitButton
      variant="primary"
      label={t('COMMON:buttons.continue') || ''}
      onClick={handleFormSubmit}
      disabled={!!Object.keys(errors).length}
      adaptiveWidth
      {...addTestAttribute('registration.button.submit')}
    />
  );
});
