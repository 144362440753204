import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorBoundary } from 'react-error-boundary';

import {
  BoundaryErrorType,
  getMismatches,
  useTimer,
  FlkCode,
  addTestAttribute,
} from '@shared/index';

import { useRefreshCode } from '../../api';
import { ResendComponent, ResendButton } from './resend.styles';
import type { ResendProps } from './resend.types';

const EXPIRE_PERIOD = 30;
const getExpiryTimestamp = () => Date.now() / 1000 + EXPIRE_PERIOD;

export const Resend = ({
  onSuccess,
  onError,
  onResend,
  phone,
}: ResendProps) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { seconds, restart } = useTimer({
    expiryTimestamp: getExpiryTimestamp(),
  });

  const { res, refetch, error, isFetching } = useRefreshCode({
    phone,
    deps: [phone],
  });
  const handleResend = () => {
    refetch();
    onResend && onResend();
    restart(getExpiryTimestamp());
  };

  useEffect(() => {
    if (!isFetching && res) {
      onSuccess && onSuccess(res.token);
    }
  }, [isFetching, res]);

  useEffect(() => {
    if (error) {
      const mismatches = getMismatches(error);

      if (!!mismatches) {
        mismatches.forEach(({ code }) => {
          if (
            code === FlkCode.OTP_0101_0001 ||
            code === FlkCode.OTP_0101_0002 ||
            code === FlkCode.OTP_0101_0003
          ) {
            // Неверный номер телефона
            onError && onError(t('COMMON:errors.incorrectPhoneNumber'));
          } else {
            showBoundary(BoundaryErrorType.SomethingWentWrong);
          }
        });
      } else {
        showBoundary(BoundaryErrorType.SomethingWentWrong);
      }
    }
  }, [error]);

  return (
    <ResendComponent>
      {seconds > 0 ? (
        t('COMMON:hints.getNewCodeIn', {
          seconds: seconds < 10 ? `0${seconds}` : seconds,
        })
      ) : (
        <ResendButton
          onClick={handleResend}
          {...addTestAttribute('otp.resendButton')}
        >
          {t('COMMON:buttons.getNewCode')}
        </ResendButton>
      )}
    </ResendComponent>
  );
};
