export enum FlkCode {
  RMM_6010_0001 = 'RMM_6010_0001',
  RMM_6010_0002 = 'RMM_6010_0002',
  RMM_6010_0003 = 'RMM_6010_0003',
  RMM_6010_0004 = 'RMM_6010_0004',
  RMM_6010_0005 = 'RMM_6010_0005',
  RMM_6010_0006 = 'RMM_6010_0006',
  RMM_6010_0007 = 'RMM_6010_0007',
  RMM_6010_0008 = 'RMM_6010_0008',
  RMM_6010_0009 = 'RMM_6010_0009',
  RMM_6010_0010 = 'RMM_6010_0010',
  RMM_6010_0011 = 'RMM_6010_0011',
  RMM_6010_0012 = 'RMM_6010_0012',
  RMM_6010_0013 = 'RMM_6010_0013',
  RMM_6010_0014 = 'RMM_6010_0014',
  RMM_6010_0015 = 'RMM_6010_0015',
  RMM_6020_0005 = 'RMM_6020_0005',
  RMM_6020_0006 = 'RMM_6020_0006',
  RMM_6020_0001 = 'RMM_6020_0001',
  RMM_6020_0002 = 'RMM_6020_0002',
  RMM_6020_0003 = 'RMM_6020_0003',
  RMM_6020_0004 = 'RMM_6020_0004',
  RMM_6020_0007 = 'RMM_6020_0007',
  RMM_6020_0008 = 'RMM_6020_0008',
  AGT_0101_0001 = 'AGT_0101_0001',
  AGT_0101_0002 = 'AGT_0101_0002',
  OTP_0101_0001 = 'OTP_0101_0001',
  OTP_0101_0002 = 'OTP_0101_0002',
  OTP_0101_0003 = 'OTP_0101_0003',
  OTP_0201_0001 = 'OTP_0201_0001',
  OTP_0201_0002 = 'OTP_0201_0002',
  OTP_0201_0003 = 'OTP_0201_0003',
  RMM_6040_0002 = 'RMM_6040_0002',
  AGT_1301_0003 = 'AGT_1301_0003',
  AGT_01_0011 = 'AGT_01_0011',
  AGT_01_0012 = 'AGT_01_0012',
  AGT_01_0013 = 'AGT_01_0013',
  AGT_01_0014 = 'AGT_01_0014',
  AGT_34_0001 = 'AGT_34_0001',
  AGT_34_0002 = 'AGT_34_0002',
  AGT_3501_0001 = 'AGT_3501_0001',
  AGT_3501_0002 = 'AGT_3501_0002',
  AGT_3501_0003 = 'AGT_3501_0003',
  AGT_3501_0004 = 'AGT_3501_0004',
  AGT_4701_0002 = 'AGT_4701_0002',
}
