import type { KeyboardEventHandler } from 'react';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';

import type { DaDataFio, DaDataSuggestion } from '@pulse-web-ui/dadata';
import { HelperText } from '@pulse-web-ui/helper-text';

import { DadataFioSuggestions, type FilterParts } from '@entities/index';
import { addTestAttribute } from '@shared/index';

const getDadataValue = (value: string | undefined) =>
  ({ value: value || '' }) as DaDataSuggestion<DaDataFio>;

type Props<T extends FieldValues> = UseControllerProps<T> & {
  label: string;
  filterParts: FilterParts[];
  onChange: (val: string) => void;
  id?: string;
  disabled?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  testId?: string;
};

export const DadataFioController = <T extends FieldValues>({
  label,
  filterParts,
  id,
  onChange,
  disabled,
  onKeyDown,
  testId = 'dadataFio',
  ...controllerProps
}: Props<T>) => {
  const { field, fieldState } = useController(controllerProps);

  return (
    <HelperText
      status={!!fieldState.error ? 'error' : 'default'}
      message={fieldState.error?.message}
      testId={`${testId}.error`}
    >
      <DadataFioSuggestions
        filterParts={filterParts}
        onChange={(data) => {
          onChange(data?.value || field.value);
        }}
        defaultQuery={field.value}
        uid={id}
        value={getDadataValue(field.value)}
        inputProps={{
          ...field,
          label,
          error: !!fieldState.error,
          onKeyDown,
          disabled,
          ...addTestAttribute(testId),
        }}
      />
    </HelperText>
  );
};
