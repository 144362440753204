import { useRequest, RequestMethods, AgentEndpoints } from '@src/shared';
import type { RequestDeps } from '@src/shared/types/request.types';
import type { CommonResponse } from '@src/shared/types';

import type {
  CreateAgentRequestParams,
  CreateAgentResponse,
} from './create-agent.types';

export const useCreateAgent = (
  request: CreateAgentRequestParams,
  deps: RequestDeps
) => {
  const { res, isLoading, refetch, error, status, isFetching } = useRequest<
    CreateAgentRequestParams,
    CreateAgentResponse,
    CommonResponse
  >({
    params: {
      key: 'createAgent',
      method: RequestMethods.POST,
      url: `${AgentEndpoints.AGENTS}/draft`,
      request,
      fetchManual: true,
      deps,
    },
  });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
  };
};
