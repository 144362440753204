import { useCallback } from 'react';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { HelperText } from '@pulse-web-ui/helper-text';

import { Select } from '@shared/index';
import type { Option } from '@shared/ui/select/select.types';

type Props<T extends FieldValues> = UseControllerProps<T> & {
  options: Option[];
  label: string;
  onChange?: (val?: string) => void;
  disabled?: boolean;
  testId?: string;
};

export const SelectController = <T extends FieldValues>({
  options,
  label,
  onChange,
  disabled,
  testId = 'form',
  ...controllerProps
}: Props<T>) => {
  const { field, fieldState } = useController(controllerProps);

  const handleChange = useCallback(
    (value?: string) => {
      field.onChange(value);
      onChange && onChange(value);
    },
    [field, onChange]
  );

  return (
    <HelperText
      status={!!fieldState.error ? 'error' : 'default'}
      message={fieldState.error?.message}
    >
      <Select
        label={label}
        options={options}
        value={field.value}
        onChange={handleChange}
        error={!!fieldState.error}
        disabled={disabled}
        testId={testId}
      />
    </HelperText>
  );
};
