import React from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { StateBlock } from '@shared/index';

import somethingWentWrongIcon from '@assets/images/something-went-wrong.svg';

export const ErrorBoundaryFallback = ({
  resetErrorBoundary,
}: FallbackProps) => {
  const { t } = useTranslation();

  return (
    <StateBlock
      title={t('COMMON:headers.somethingWentWrong')}
      description={t('COMMON:subHeaders.tryAgainOrContactSupport')}
      icon={somethingWentWrongIcon}
      submitButtonText={t('COMMON:buttons.tryAgain')}
      onSubmit={() => resetErrorBoundary()}
    />
  );
};
