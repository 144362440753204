import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { WidgetContainer } from '@pulse-web-ui/containers';
import { Act } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';

import {
  addTestAttribute,
  AgentDocumentType,
  useOpenFile,
  useStores,
} from '@shared/index';

import {
  DocumentItem,
  FlexContainer,
  Value,
  StyledWidgetHeader,
} from './profile.documents.styles';
import { useGetDocument, type GetDocumentRequestParams } from '../../api';

export const ProfileDocuments = observer(() => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const [requestParams, setRequestParams] = useState<GetDocumentRequestParams>({
    searchParameters: {
      agentDocumentType: AgentDocumentType.DOCUMENT,
      fileId: '',
    },
  });
  const {
    MainStore: {
      agentContractStore: { agentDocuments },
    },
  } = useStores();
  const { res, isFetching, refetch, error } = useGetDocument(requestParams, [
    requestParams,
  ]);

  const onOpenFile = useOpenFile({
    res,
    isFetching,
    error,
  });

  const handleDocumentDownload = (
    documentType: AgentDocumentType,
    id: string
  ) => {
    onOpenFile();
    setRequestParams({
      searchParameters: { agentDocumentType: documentType, fileId: id },
    });
  };

  useEffect(() => {
    if (requestParams.searchParameters.fileId) {
      refetch();
    }
  }, [requestParams]);

  return (
    <WidgetContainer>
      <StyledWidgetHeader>
        {t('AGENT_PROFILE:headers.documents')}
      </StyledWidgetHeader>
      <FlexContainer>
        {!!agentDocuments?.length &&
          agentDocuments.map(({ description, agentDocumentType, fileId }) => (
            <DocumentItem
              key={`${description}-${fileId}`}
              onClick={() => handleDocumentDownload(agentDocumentType, fileId)}
              {...addTestAttribute(`agent-document-${fileId}`)}
            >
              <Act color={theme.colors.icon.primary} width={20} />
              <Value>{description}</Value>
            </DocumentItem>
          ))}
      </FlexContainer>
    </WidgetContainer>
  );
});
