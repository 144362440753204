import { type MouseEventHandler, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import {
  type CommonResponse,
  ConsentType,
  FormControlContainer,
  AnalyticEvent,
  useOpenFile,
  useStores,
  addTestAttribute,
  CheckboxController,
} from '@src/shared';
import { sendAnalyticEvent } from '@src/app/web-analytic';
import {
  useGetConsent,
  useGetContractDraft,
} from '@src/entities/agent-contract';

import { AgentContractAgreementsWrapper } from './agent-contract-agreements.styles';
import type { AgentContractAgreementsProps } from '../model';

export const AgentContractAgreements = observer(() => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<AgentContractAgreementsProps>();
  const {
    MainStore: {
      agentContractAgreementsStore: {
        contractConditionsAgreement,
        personalDataManagement,
        setContractConditionsAgreement,
        setPersonalDataManagement,
      },
    },
  } = useStores();

  const {
    res: contractDraftRes,
    error: contractDraftError,
    refetch: refetchContractDraft,
    isFetching: isContractDraftFetching,
  } = useGetContractDraft();

  const {
    res: consentRes,
    error: consentError,
    refetch: refetchConsent,
    isFetching: isConsentFetching,
  } = useGetConsent(ConsentType.DistributionPersonalDataManagement);

  const onOpenContractDraft = useOpenFile<CommonResponse>({
    res: contractDraftRes,
    error: contractDraftError,
    isFetching: isContractDraftFetching,
    queryKeyToClearCache: 'getAgentContractDraft',
  });

  const onOpenConsent = useOpenFile<CommonResponse>({
    res: consentRes,
    error: consentError,
    isFetching: isConsentFetching,
    queryKeyToClearCache: `getAgentConsent_${ConsentType.DistributionPersonalDataManagement}`,
  });

  const handleContractConditionsAgreementClick: MouseEventHandler<
    HTMLAnchorElement
  > = (event) => {
    event.preventDefault();
    onOpenContractDraft();
    refetchContractDraft();
    sendAnalyticEvent(AnalyticEvent.AGENT_AGREEMENT_TERMS_FORM);
  };

  const handlePersonalDataManagementClick: MouseEventHandler<
    HTMLAnchorElement
  > = (event) => {
    event.preventDefault();
    onOpenConsent();
    refetchConsent();
    sendAnalyticEvent(AnalyticEvent.AGENT_AGREEMENT_PDN_FORM);
  };

  useEffect(() => {
    setValue('contractConditionsAgreement', contractConditionsAgreement);
    setValue('personalDataManagement', personalDataManagement);
  }, []);

  return (
    <AgentContractAgreementsWrapper>
      <FormControlContainer>
        <CheckboxController
          control={control}
          name="contractConditionsAgreement"
          id="contractConditionsAgreement"
          label={
            <Trans
              components={{
                a: (
                  <a
                    onClick={handleContractConditionsAgreementClick}
                    {...addTestAttribute(
                      'agentContractAgreements.conditions.link'
                    )}
                  />
                ),
              }}
            >
              {t(
                'AGENT_CONTRACT_AGREEMENTS:labels.agreeWithContractConditions'
              )}
            </Trans>
          }
          onChange={(val) => {
            setContractConditionsAgreement(val);
          }}
          testId="agentContractAgreements.conditions.checkbox"
        />
      </FormControlContainer>
      <FormControlContainer margin="0 0 24px 0">
        <CheckboxController
          control={control}
          name="personalDataManagement"
          label={
            <Trans
              components={{
                a: (
                  <a
                    onClick={handlePersonalDataManagementClick}
                    {...addTestAttribute(
                      'agentContractAgreements.personalDataManagement.link'
                    )}
                  />
                ),
              }}
            >
              {t(
                'AGENT_CONTRACT_AGREEMENTS:labels.agreeWithPersonalDataManagement'
              )}
            </Trans>
          }
          onChange={(val) => {
            setPersonalDataManagement(val);
          }}
          testId="agentContractAgreements.personalDataManagement.checkbox"
        />
      </FormControlContainer>
    </AgentContractAgreementsWrapper>
  );
});
