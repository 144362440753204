import * as yup from 'yup';
import i18n from '@src/app/i18n/config';

import { validatePassword } from '../../lib';

export const createPasswordSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required(i18n.t('COMMON:errors.required'))
      .min(8, i18n.t('PASSWORD:errors.password'))
      .max(20, i18n.t('PASSWORD:errors.password'))
      .test('isPasswordValid', i18n.t('PASSWORD:errors.password'), (value) =>
        validatePassword(value)
      ),
    confirmPassword: yup
      .string()
      .required(i18n.t('COMMON:errors.required'))
      .oneOf([yup.ref('password')], i18n.t('PASSWORD:errors.confirmPassword')),
  })
  .required();
