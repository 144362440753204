import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@pulse-web-ui/theme';
import { format } from 'date-fns';
import { Act } from '@pulse-web-ui/icons';

import {
  DateFormat,
  LabelValue,
  LabelValueList,
  Status,
  type StatusType,
  ruNumberFormat,
  ActStatus,
  AgentDocumentType,
  useOpenFile,
  addTestAttribute,
  SubmitButton,
} from '@shared/index';
import { useGetDocument } from '@entities/index';
import type { AgentAct } from '../model';
import { ActWrapper, Container } from './agent-act-card.styles';

const STATUS_TYPE_MAP: Record<ActStatus, StatusType> = {
  [ActStatus.FORMED]: 'warning',
  [ActStatus.PAID]: 'success',
  [ActStatus.SIGNED]: 'success',
};

interface AgentActCardProps {
  agentAct: AgentAct;
  onSignAct: (id: string) => void;
  dataIndex?: number;
}

export const AgentActCard = forwardRef<HTMLDivElement, AgentActCardProps>(
  ({ agentAct, onSignAct, dataIndex }, ref) => {
    const {
      actId,
      codeStatus,
      nameStatus,
      actNumber,
      actDate,
      commissionAll,
      commissionNet,
      actStartDate,
      actEndDate,
      fileId,
    } = agentAct;
    const { t } = useTranslation();
    const theme: any = useTheme();
    const { isFetching, refetch, res, error } = useGetDocument(
      {
        searchParameters: {
          agentDocumentType: AgentDocumentType.ACT,
          fileId,
        },
      },
      [fileId]
    );

    const onOpenFile = useOpenFile({ res, isFetching, error });

    const handleActDownload = () => {
      onOpenFile();
      refetch();
    };

    const isCommissionNetShown = commissionNet < commissionAll;

    return (
      <Container ref={ref} data-index={dataIndex}>
        <Status type={STATUS_TYPE_MAP[codeStatus]}>{nameStatus}</Status>
        <LabelValueList>
          <LabelValue
            label={t('AGENT_ACT_CARD:labels.number')}
            value={actNumber}
          />
          <LabelValue
            label={t('AGENT_ACT_CARD:labels.date')}
            value={format(new Date(actDate), DateFormat.DD_MM_YYYY)}
          />
          <LabelValue
            label={t('AGENT_ACT_CARD:labels.commissionBrutto')}
            value={`${ruNumberFormat(Math.ceil(commissionAll))} ₽`}
          />
          {isCommissionNetShown && (
            <LabelValue
              label={t('AGENT_ACT_CARD:labels.commissionNetto')}
              value={`${ruNumberFormat(Math.ceil(commissionNet))} ₽`}
            />
          )}
          <LabelValue
            label={t('AGENT_ACT_CARD:labels.startDate')}
            value={format(new Date(actStartDate), DateFormat.DD_MM_YYYY)}
          />
          <LabelValue
            label={t('AGENT_ACT_CARD:labels.endDate')}
            value={format(new Date(actEndDate), DateFormat.DD_MM_YYYY)}
          />
          <ActWrapper
            onClick={handleActDownload}
            {...addTestAttribute(`act-card-${dataIndex}-download-document`)}
          >
            <Act color={theme.colors.icon.primary} width={20} />
            {t('AGENT_ACT_CARD:labels.act')}
          </ActWrapper>
        </LabelValueList>
        {codeStatus === ActStatus.FORMED && (
          <SubmitButton
            onClick={() => onSignAct(actId)}
            variant="primary"
            label={t('AGENT_ACT_CARD:buttons.sign')}
            adaptiveWidth
            {...addTestAttribute(`act-card-${dataIndex}-sign-button`)}
          />
        )}
      </Container>
    );
  }
);
