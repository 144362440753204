/* eslint-disable indent */
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { Spinner } from '@pulse-web-ui/spinner';

import {
  AddressType,
  DateFormat,
  PageHeader,
  TextLink,
  phoneDisplayValueCasting,
  useStores,
} from '@shared/index';
import {
  AgentProfileContainer,
  FlexContainer,
  Label,
  StyledWidgetHeader,
  Value,
} from './agent-profile.styles';
import {
  getBankDetailsTranslations,
  getGenderLabels,
  getPersonalDataTranslations,
} from '../../utils';
import type { ProfilePersonalData } from '../../model';
import { SupportLinkHint } from '../support-link-hint';
import { ProfileDocuments } from '../profile-documents';

export const AgentProfile = observer(() => {
  const { t } = useTranslation();
  const {
    MainStore: {
      agentContractStore: { profile },
    },
  } = useStores();

  const agentAddress = profile?.addressesAgents?.find(
    ({ addressType }) => addressType === AddressType.REGISTRATION
  );
  const agentActualAddress = profile?.addressesAgents?.find(
    ({ addressType }) => addressType === AddressType.ACCOMMODATION
  );
  const personalDataLabels = getPersonalDataTranslations(t);
  const bankDetailsLabels = getBankDetailsTranslations(t);
  const genderLabels = getGenderLabels(t);
  const userHasBankDetails = [
    profile?.bankDetails?.bic,
    profile?.bankDetails?.nameBank,
    profile?.bankDetails?.corAccount,
    profile?.bankDetails?.bankAccount,
  ].some((item) => !!item);

  const personalData: ProfilePersonalData = {
    lastName: profile?.lastName,
    firstName: profile?.firstName,
    middleName: profile?.middleName,
    gender: profile?.gender ? genderLabels[profile.gender] : undefined,
    agentCode: profile?.agentCode,
    birthDate: profile?.birthDate
      ? format(new Date(profile.birthDate), DateFormat.DD_MM_YYYY)
      : undefined,
    phone: profile?.phone ? phoneDisplayValueCasting(profile.phone) : undefined,
    email: profile?.email,
    address: agentAddress?.address,
    actualAddress: agentActualAddress?.address,
    passportSeriesNumber: profile?.personalDocument?.numberPassport
      ? `${profile?.personalDocument?.seriesPassport} ${profile?.personalDocument?.numberPassport}`
      : undefined,
    codeDepartment: profile?.personalDocument?.codeDepartment,
    nameDepartment: profile?.personalDocument?.nameDepartment,
    dateIssue: profile?.personalDocument?.dateIssue
      ? format(
          new Date(profile?.personalDocument?.dateIssue),
          DateFormat.DD_MM_YYYY
        )
      : undefined,
    snils: profile?.personalDocument?.snils,
    inn: profile?.personalDocument?.inn,
  };

  if (!profile) {
    return <Spinner />;
  }

  return (
    profile && (
      <AgentProfileContainer>
        <PageHeader padding={[0, 16]}>
          {t('AGENT_PROFILE:headers.profile')}
        </PageHeader>
        <WidgetContainer>
          <StyledWidgetHeader>
            {t('AGENT_PROFILE:headers.personalData')}
          </StyledWidgetHeader>
          <FlexContainer>
            {Object.keys(personalData).map((objKey) => {
              const label = objKey as keyof typeof personalData;
              if (personalData[label]) {
                return (
                  <div key={label}>
                    <Label>{personalDataLabels[label]}</Label>
                    <Value>{personalData[label]}</Value>
                  </div>
                );
              }
            })}
            {profile.personalDocument?.website && (
              <TextLink
                href={profile.personalDocument?.website}
                target="_blank"
              >
                <Label>{personalDataLabels.website}</Label>
                <Value>{profile.personalDocument.website}</Value>
              </TextLink>
            )}
            <SupportLinkHint />
          </FlexContainer>
        </WidgetContainer>
        {!!profile.bankDetails && userHasBankDetails && (
          <WidgetContainer>
            <StyledWidgetHeader>
              {t('AGENT_PROFILE:headers.bankDetails')}
            </StyledWidgetHeader>
            <FlexContainer>
              {Object.keys(profile.bankDetails).map((objKey) => {
                const label = objKey as keyof typeof profile.bankDetails;
                if (profile.bankDetails![label]) {
                  return (
                    <div key={objKey}>
                      <Label>{bankDetailsLabels[label]}</Label>
                      <Value>{profile.bankDetails![label]}</Value>
                    </div>
                  );
                }
              })}
              <SupportLinkHint />
            </FlexContainer>
          </WidgetContainer>
        )}
        <ProfileDocuments />
      </AgentProfileContainer>
    )
  );
});
