import { makeAutoObservable, runInAction } from 'mobx';

import type { Main } from '@app/store';

import type { BicData } from '..';
import type { AgentBranch } from '../types/agentBranch.types';

export class DictionariesStore {
  private mainStore: Main;
  // BIC
  bicDictionary?: BicData[];
  isBicDictionaryLoaded?: boolean;
  // AgentBranch
  agentBranchDictionary?: AgentBranch[];
  isAgentBranchDictionaryLoaded?: boolean;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setBicDictionary = (dictionary: BicData[]) => {
    runInAction(() => {
      this.bicDictionary = dictionary;
      this.isBicDictionaryLoaded = true;
    });
  };

  setAgentBranchDictionary = (dictionary: AgentBranch[]) => {
    runInAction(() => {
      this.agentBranchDictionary = dictionary;
      this.isAgentBranchDictionaryLoaded = true;
    });
  };

  reset() {
    runInAction(() => {
      this.bicDictionary = undefined;
      this.isBicDictionaryLoaded = undefined;
    });
  }
}
