import { useRequest, RequestMethods } from '@src/shared';
import type { RequestDeps } from '@src/shared/types/request.types';

import type { RefreshCodeResponse } from './refresh-code.types';
import { UserEndpoints } from '@src/entities';
import type { CommonResponse } from '@src/shared/types';

type useRefreshCodeProps = {
  phone: string | undefined;
  deps?: RequestDeps;
};

export const useRefreshCode = ({ phone, deps }: useRefreshCodeProps) => {
  const { res, isLoading, refetch, error, status, isFetching, isRefetching } =
    useRequest<never, RefreshCodeResponse, CommonResponse>({
      params: {
        key: 'refresh-code',
        method: RequestMethods.GET,
        url: `${UserEndpoints.USERS}/${phone}/refresh-code/otp`,
        fetchManual: true,
        deps,
      },
    });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
    isRefetching,
  };
};
