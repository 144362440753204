import type { AgreementsFormProps } from '@src/features/agreements';
import { ConsentType } from '../constants';

export const getConsents = ({
  personalDataAgreement,
  specialOffersAgreement,
}: AgreementsFormProps) => {
  const consents = [];

  if (personalDataAgreement) {
    consents.push({
      consentType: ConsentType.AgentDataManagement,
      approvedAt: new Date().toISOString(),
    });
    consents.push({
      consentType: ConsentType.ElectronicDocumentManagement,
      approvedAt: new Date().toISOString(),
    });
  }

  if (specialOffersAgreement) {
    consents.push({
      consentType: ConsentType.AgentActiveCommunication,
      approvedAt: new Date().toISOString(),
    });
  }

  return consents;
};
