import amplitude from 'amplitude-js';
import ym from 'react-yandex-metrika';

import type { AnalyticEvent } from '@shared/index';

export const sendAnalyticEvent = <T>(
  triggerEvent: AnalyticEvent,
  params?: T
): void => {
  ym('reachGoal', triggerEvent, params);
  amplitude.getInstance().logEvent(triggerEvent, params);
};
