import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { addTestAttribute, RGS_AGENT_REGISTRATON } from '@shared/index';
import { StyledRGSAgentRegistrationButton } from './rgs-agent-registration-button.styles';

export const IS_RGS_AGENT_SEARCH_PARAMS = 'rgs-agent';

export const RGSAgentRegistrationButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickImRGSAgent = () => {
    navigate(RGS_AGENT_REGISTRATON);
  };

  return (
    <StyledRGSAgentRegistrationButton
      variant="text"
      label={t('REGISTRATION:button.rgsAgent')}
      onClick={onClickImRGSAgent}
      {...addTestAttribute('registration.button.rgsAgent')}
    />
  );
};
