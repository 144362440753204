import React, { type ReactNode } from 'react';

import { LayoutContainer } from './layout.styles';
import { Header } from '../header';

interface Props {
  children: ReactNode;
}

export const Layout = ({ children }: Props) => {
  return (
    <LayoutContainer>
      <Header />
      {children}
    </LayoutContainer>
  );
};
