import {
  type InputHTMLAttributes,
  type ReactNode,
  forwardRef,
  type Ref,
} from 'react';
import {
  type FieldValues,
  useController,
  type UseControllerProps,
} from 'react-hook-form';

import { HelperText } from '@pulse-web-ui/helper-text';
import { type HintType, Input } from '@pulse-web-ui/input';
import type { TriggerDirection } from '@pulse-web-ui/tooltip';
import { addTestAttribute } from '@shared/index';

type InputControllerProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string;
  id?: string;
  disabled?: boolean;
  maxLength?: number;
  mask?: (string | RegExp)[];
  placeholder?: string;
  type?: string;
  inputMode?: InputHTMLAttributes<HTMLInputElement>['inputMode'];
  hasTooltip?: boolean;
  hintObject?: HintType;
  trigger?: TriggerDirection;
  tooltipText?: ReactNode;
  hint?: string;
  onChange?: (val: string) => void;
  testId?: string;
};

const InputControllerWithoutRef = <T extends FieldValues>(
  {
    label,
    id,
    disabled,
    maxLength,
    mask,
    placeholder,
    type,
    inputMode,
    hasTooltip,
    hintObject,
    trigger,
    tooltipText,
    hint,
    onChange,
    testId = 'form',
    ...controllerProps
  }: InputControllerProps<T>,
  ref: Ref<HTMLInputElement>
) => {
  const { field, fieldState } = useController(controllerProps);

  const onInputChange = (value: string) => {
    field.onChange(value);
    onChange?.(value);
  };

  return (
    <HelperText
      status={!!fieldState.error ? 'error' : 'default'}
      message={fieldState.error?.message || hint}
      testId={`${testId}.error`}
    >
      <Input
        ref={ref}
        onChange={onInputChange}
        id={id}
        value={field.value}
        label={label}
        error={!!fieldState.error}
        disabled={disabled}
        maxLength={maxLength}
        mask={mask}
        placeholder={placeholder}
        type={type}
        inputMode={inputMode}
        hasTooltip={hasTooltip}
        hintObject={hintObject}
        trigger={trigger}
        tooltipText={tooltipText}
        autoComplete="nope"
        {...addTestAttribute(`${testId}.input`)}
      />
    </HelperText>
  );
};

export const InputController = forwardRef(InputControllerWithoutRef) as <
  T extends FieldValues,
>(
  p: InputControllerProps<T> & { ref?: Ref<HTMLInputElement> }
) => JSX.Element;
