import { useRequest, RequestMethods, AgentEndpoints } from '@shared/index';
import type { RequestDeps, CommonResponse } from '@shared/index';

import type {
  CreateImportedAgentRequestParams,
  CreateImportedAgentResponse,
} from './create-imported.types';

export const useCreateImportedAgent = (
  request: CreateImportedAgentRequestParams,
  deps: RequestDeps
) => {
  const { res, isLoading, refetch, error, status, isFetching } = useRequest<
    CreateImportedAgentRequestParams,
    CreateImportedAgentResponse,
    CommonResponse
  >({
    params: {
      key: 'createImportedAgent',
      method: RequestMethods.POST,
      url: `${AgentEndpoints.AGENTS}/draft-imported`,
      request,
      fetchManual: true,
      deps,
    },
  });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
  };
};
