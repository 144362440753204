import type { ReactNode } from 'react';

import { WidgetHeader, WidgetSubHeaderText } from './';

interface Props {
  title: string;
  subTitle?: ReactNode | string;
  marginBottom?: number;
}

export const WidgetHeaderWithSubText = ({
  title,
  subTitle,
  marginBottom,
}: Props) => (
  <>
    <WidgetHeader style={{ marginBottom: !!subTitle ? 8 : 16 }}>
      {title}
    </WidgetHeader>
    {subTitle && (
      <WidgetSubHeaderText marginBottom={marginBottom}>
        {subTitle}
      </WidgetSubHeaderText>
    )}
  </>
);
