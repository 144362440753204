import type { LoginResponse } from '@shared/types';

import type { ParsedUserToken } from './types';

export const LocalStorageUserInfoKeys = {
  name: 'DAUser-Name',
  acc_exp: 'DAUser-AccExp',
  ref_exp: 'DAUser-RefExp',
};

export const isLoggedIn = () =>
  localStorage.getItem(LocalStorageUserInfoKeys.name) !== null &&
  localStorage.getItem(LocalStorageUserInfoKeys.ref_exp) !== null &&
  localStorage.getItem(LocalStorageUserInfoKeys.acc_exp) !== null;

export const removeUserInfoFromLocalStorage = () => {
  localStorage.removeItem(LocalStorageUserInfoKeys.name);
  localStorage.removeItem(LocalStorageUserInfoKeys.acc_exp);
  localStorage.removeItem(LocalStorageUserInfoKeys.ref_exp);
};

const parseJwt = (token: string): ParsedUserToken => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((el) => {
        // handle 'Unicode Problem' - Character Out Of Range
        const domStringBase = `00${el.charCodeAt(0).toString(16)}`;

        return `%${domStringBase.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload) as ParsedUserToken;
};

export const getCurrentUserInfo = () => {
  const name = localStorage.getItem(LocalStorageUserInfoKeys.name);
  const accessExpires = localStorage.getItem(
    LocalStorageUserInfoKeys.acc_exp
  ) as string;
  const refreshExpires = localStorage.getItem(
    LocalStorageUserInfoKeys.ref_exp
  ) as string;
  const accessExpDate = new Date(parseInt(accessExpires, 10) * 1000);
  const sessionExpDate = new Date(parseInt(refreshExpires, 10) * 1000);

  return {
    name,
    accessExpDate,
    sessionExpDate,
  };
};

export const putAuthenticationInfoInLocalStorage = (
  response: LoginResponse
) => {
  const parsedAccessToken = parseJwt(response.access_token);
  const parsedRefreshToken = parseJwt(response.refresh_token);
  localStorage.setItem(
    LocalStorageUserInfoKeys.name,
    parsedRefreshToken.user_name
  );
  localStorage.setItem(
    LocalStorageUserInfoKeys.acc_exp,
    `${parsedAccessToken.exp}`
  );
  localStorage.setItem(
    LocalStorageUserInfoKeys.ref_exp,
    `${parsedRefreshToken.exp}`
  );
};
