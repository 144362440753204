import { makeAutoObservable, runInAction } from 'mobx';

import type { Main } from '@app/store';
import type { Products } from '../types';

export class ProductsStore {
  private mainStore: Main;
  products?: Products;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setProducts = (products: Products) => {
    this.products = products;
  };

  reset() {
    runInAction(() => {
      this.products = undefined;
    });
  }
}
