import * as yup from 'yup';
import i18n from '@app/i18n/config';
import { phoneRegEx } from '@shared/index';

export const agentRegistrationSchema = yup
  .object()
  .shape({
    phone: yup
      .string()
      .required(i18n.t('COMMON:errors.required'))
      .matches(phoneRegEx, i18n.t('COMMON:errors.incorrectPhoneNumber')),
  })
  .required();
