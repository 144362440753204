import { lazy, Suspense } from 'react';
import { useTheme } from '@pulse-web-ui/theme';

import { Hint, IconContainer } from './field-validation-hint.styles';

const CheckIcon = lazy(() => import('@pulse-web-ui/icons/lib/lazy/check'));
const CrossIcon = lazy(() => import('@pulse-web-ui/icons/lib/lazy/close'));

interface FieldValidationHintProps {
  text: string;
  isValid: boolean;
  isErrorTriggered: boolean;
}

export const FieldValidationHint = ({
  text,
  isValid,
  isErrorTriggered,
}: FieldValidationHintProps) => {
  const theme: any = useTheme();

  return (
    <Hint $isError={!isValid && isErrorTriggered}>
      <IconContainer>
        <Suspense>
          {isValid ? (
            <CheckIcon
              width={20}
              viewBox="0 0 36 36"
              color={theme.colors.icon.success}
            />
          ) : (
            <CrossIcon
              width={20}
              viewBox="0 0 28 28"
              color={theme.colors.icon.error}
            />
          )}
        </Suspense>
      </IconContainer>
      {text}
    </Hint>
  );
};
