import { useRequest, RequestMethods, AgentEndpoints } from '@src/shared';
import type { RequestDeps } from '@src/shared/types/request.types';
import type { CommonResponse } from '@src/shared/types';

import type {
  UpdateProfileRequestParams,
  UpdateProfileResponse,
} from './update-profile.types';

export const useUpdateProfile = (
  request: UpdateProfileRequestParams,
  deps: RequestDeps
) => {
  const { res, isLoading, refetch, error, status, isFetching } = useRequest<
    UpdateProfileRequestParams,
    UpdateProfileResponse,
    CommonResponse
  >({
    params: {
      key: 'updateProfile',
      method: RequestMethods.PUT,
      url: `${AgentEndpoints.AGENTS}/update-profile`,
      request,
      fetchManual: true,
      deps,
    },
  });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
  };
};
